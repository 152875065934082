import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import "./Checkout.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Carousel } from 'bootstrap';
import { Modal } from "bootstrap";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { getAsset } from "../utils/helper";
import Env from "../Environment/Env";
import { useNavigate } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../Store/CartSlice";

const Checkout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("description");
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [data, setData] = useState({});
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllById/${id}`)
      .then((res) => {
        console.log("res===", res.data.subsubcatagories);
        setData(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err=====", err);
      });
  }, []);

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleColorClick = (color) => {
    console.log("Clicked color ID:", color.id);
    console.log("Clicked color Name:", color.name);
  };

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
    console.log(quantity + 1);
  };

  const openCart = () => {
    // const modal = new Modal(document.getElementById('exampleModal'));
    // modal.hide();
    if (modal) {
      modal.hide();
    }
    navigate("/Cart1");
  };

  const handleCardClick = (selectedProduct, quan, color) => {
    if(selectedColor !== ""){
      // selectedProduct = {...selectedProduct ,selectedColor};
      setSelectedColor(color); // Update the selected color state
    }
    if (quan === undefined) {
      quan = 1;
    } else if (quan > selectedProduct.qty) {
      alert("Quantity should be less than " + selectedProduct.qty);
    } else if (quan > 0) {
      console.log('Here is the existing data===>>>',selectedProduct);
      let existingData =
        JSON.parse(localStorage.getItem("selectedCartItems")) || [];
      const index = existingData.findIndex( 
        (item) => item._id === selectedProduct._id
      );
      if (index !== -1) {
        existingData[index] = {
          ...existingData[index],
          quantity: quan,
          userid: localStorage.getItem("id"),   
          selectedColor: color, // Update selected color
          
        };
      } else {
        existingData.push({
          ...selectedProduct,
          quantity: quan,
          userid: localStorage.getItem("id"),
          selectedColor: color, // Update selected color
        });
      }

      localStorage.setItem("selectedCartItems", JSON.stringify(existingData));
      // const modal = new Modal(document.getElementById("exampleModal"));
      modal.show();
      closeModal(); // Close the modal after showing
    }
  };

  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  useEffect(() => {
    console.log(id);
    setModal(new Modal(document.getElementById("exampleModal")));
  }, []);

  useEffect(() => {
    const handleNavigation = () => {
      if (location.pathname === "/Cart1" && modal) {
        modal.hide();
      }
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [location.pathname, modal]);

  const closeModal = () => {
    if (modal) {
      modal.hide();
    }
  };

  return (
    <>
      <Navbar />
      <section>
        <div className="container-lg pt-5">
          <div
            className="display-6 text-center"
            style={{ fontFamily: "poppins", fontWeight: "bolder" }}
          >
            {data.subsubcatagory}
          </div>

          <div className="mt-1">
            <div className="row"> 
              <div className="col-lg-6 p-5 mt-2">
                <div className="">
                  <p></p>
                  <div
                    id="myCarousel"
                    className="carousel slide"
                    data-bs-interval="false"
                  >
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Product Image",
                          src: getAsset(
                            data.imageSubsubCatagory &&
                              data.imageSubsubCatagory[activeImageIndex]
                          ),
                          isFluidWidth: true,
                        },
                        largeImage: {
                          src: getAsset(
                            data.imageSubsubCatagory &&
                              data.imageSubsubCatagory[activeImageIndex]
                          ),
                          width: 1200,
                          height: 1000,
                        },
                        lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                        enlargedImageContainerStyle: { zIndex: 9 },
                      }}
                    />

                    <div className="d-none d-md-block" id="thumbCarousel">
                      {data &&
                        data.imageSubsubCatagory &&
                        data.imageSubsubCatagory.map((image, index) => (
                          <div
                            key={index}
                            onClick={() => handleThumbnailClick(index)}
                            data-bs-target="#myCarousel"
                            data-bs-slide-to={index}
                            className={`thumb ${
                              index === activeImageIndex ? "active" : ""
                            }`}
                          >
                            <img
                              src={getAsset(image)}
                              alt={`Thumbnail ${index}`}
                              style={{ height: "110px", width: "auto" }}
                            />
                          </div>
                        ))}
                    </div>

                    
                  </div>
                </div>
              </div>

              <div className="col-lg-6 p-3 mt-4" style={{ overflow: "hidden" }}>
                <p>
                  <span className="fw-bold">Brand:</span> {data.catagory}
                </p>
                <p>
                  <span className="fw-bold">Model: </span>
                  {data.subcatagory}
                </p>
                <p>
                  <span className="fw-bold">Accessory:</span>{" "}
                  {data.subsubcatagory}
                </p>
                <p>
                  <span className="fw-bold">Accessory type:</span>{" "}
                  {data.typeName}
                </p>
                <p>
                  <span className="fw-bold">Variant:</span> {data.variantName}
                </p>
                <p>
                  <span className="fw-bold">Stockkkkk:</span> {data.qty}
                </p>
                <p>
                  <span className="fw-bold">Product Price:</span> ₤{" "}
                  {data.productPrice} incl. VAT
                </p>
                <div>
                  <p>
                    <span className="fw-bold">Colors:</span>{" "}
                    {data.color
                      ? data.color.map((color, index) => (
                          <button
                          style={{backgroundColor: 'transparent', border: '1px solid lightgray', color: 'black'}}
                            key={index}
                            className="btn btn-primary me-2"
                            onClick={() =>{
                                setSelectedColor(color.name);
                                handleColorClick(color)
                              }}
                          >
                            {color.name}
                          </button>
                        ))
                      : "No Color"}
                  </p>
                </div>

               

                {/* add to basket  */}
                <div className="container-lg p-5">
                  {/* Your product details and other content here */}
                  <div className="col-lg-12 p-2 mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          borderRadius: "5px",
                        }}
                      >
                        <button
                          className="btn btn-sm"
                          onClick={decrementQuantity}
                        >
                          -
                        </button>
                        <span className="mx-2">{quantity}</span>
                        <button
                          className="btn btn-sm"
                          onClick={incrementQuantity}
                        >
                          +
                        </button>
                      </div>
                      <button
                        className="btn btn-primary btn-lg w-75 fw-bold"
                        style={{ backgroundColor: "#DB241B" }}
                        onClick={() => {
                          handleCardClick(data, quantity, selectedColor);
                        }}
                      >
                        Add to Basket
                      </button>
                    </div>
                  </div>
                </div>

                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          {quantity} item(s) added to your basket
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        {/* Your modal content */}
                        <div className="row">
                          <div className="col-12">
                            <div className="card bg-white border-white">
                              <img
                                src={getAsset(
                                  data &&
                                    data.imageSubsubCatagory &&
                                    data.imageSubsubCatagory[0]
                                )}
                                style={{ height: "auto", width: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}
                                className="card-img-top"
                                alt="Product"
                              />
                              <div className="card-body text-center">
                                {/* Text */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h6 className="card-title text-center">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Details:
                                    </span>
                                    {data.subsubcatagory}
                                  </h6>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Price:
                                  </span>
                                  <span className="card-text text-center">
                                    £{data.productPrice} per item
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Quantity:
                                  </span>
                                  <span className="card-text text-center">
                                    {quantity} {data.typeName}{" "}
                                  </span>
                                </div>

                                {selectedColor !== "" && 
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Selected Color:
                                    </span>
                                    <span className="card-text text-center">
                                      {selectedColor}
                                    </span>
                                  </div>
                                }

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Net Price:
                                  </span>
                                  <span className="card-text text-center">
                                    £{data.productPrice * quantity}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-6 mt-3 text-center bg-light">
                            {/* First button */}
                            <Link to="/">
                              <button
                                onClick={closeModal}
                                className="btn btn-primary bg-white fw-bold w-75 mt-3"
                                style={{ color: "#DB241B", fontSize: '14px' }}
                              >
                                Continue Shopping
                              </button>
                            </Link>
                          </div>
                          <div className="col-6 mt-3 text-center bg-light">
                            {/* <Link to="/Cart"> */}
                            {/* Second button */}
                            <button
                              className="btn btn-primary w-75 mt-3 fw-bold"
                              onClick={openCart}
                              style={{ backgroundColor: "#DB241B", fontSize: '14px' }}
                            >
                              {" "}
                              View Basket{" "}
                            </button>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ends her */}

                <div className="container d-flex flex-column" style={{}}>
                  <div className="d-flex pb-1" style={{ float: "right" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-alarm m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                    </svg>
                    <p className="text-new"> Recieve tommorow if you order before 2:00pm </p>
                  </div>
                  <div className="d-flex pb-1" style={{ float: "right" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-truck m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                    </svg>
                    <p href="#" className="text-new">
                      Express delivery available
                    </p>
                  </div>
                  {/* <div className="d-flex pb-1" style={{ float: "right" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-emoji-smile m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                    </svg>
                    <p className="text-new" style={{ lineHeight: "20px" }}>
                      Eligible for Super Saver Delivery
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <h2 style={{fontFamily: "poppins", fontWeight: "bolder", textAlign: 'center'}}>Related Products</h2>
     
     <div className='d-flex justify-content-start align-items-center'>
        <div className="card mb-5 border-0 bg-white" style={{ width: '13rem' }}>
          <img className="card-img-top" src="https://ik.imagekit.io/4csyk445b/eea76bce461171a474710db6392d7509.jpeg?updatedAt=1706536237035" alt="Card image cap" />
          <div className="card-body">
            <h5 className="card-title">Olixar Black S Pen - For Samsung Galaxy S23 Ultra</h5>
            <p className="card-text">£14.99 <span className="text-decoration-line-through">| £19.99</span> <span style={{ color: 'red' }}> 25% off</span></p>
            <a href="#" className="btn btn-primary" style={{backgroundColor: '#DB241B'}}>Go somewhere</a>
          </div>
        </div>
     
         
        </div> */}

<div
                      className="container-fluid mt-5"
                      style={{
                        backgroundColor: "#F8F9FA",
                        padding: "0px",
                        height: "300px",
                        marginBottom: '60px'
                      }}
                    >
                      <div
                        className="row d-flex align-items-center p-2"
                        style={{ backgroundColor: "#EAEAEA", margin: "0px" }}
                      >
                        <div
                          className={`col ${selectedOption === "description" ? "active-tab" : ""}`}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* Description Button */}
                          <button
                            onClick={() => setSelectedOption("description")}
                            style={{ border: "none", background: "none" }}
                          >
                            Description
                          </button>
                        </div>
                      
                        
                        <div
                          className={`col ${selectedOption === "specification" ? "active-tab" : ""}`}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* Specification Button */}
                          <button
                            onClick={() => setSelectedOption("specification")}
                            style={{ border: "none", background: "none" }}
                          >
                            Specification
                          </button>
                        </div>
                        <div
                          className={`col ${selectedOption === "reviews" ? "active-tab" : ""}`}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* Reviews Button */}
                          <button
                            onClick={() => setSelectedOption("reviews")}
                            style={{ border: "none", background: "none" }}
                          >
                            Reviews
                          </button>
                        </div>
                      </div>

                      {/* Content based on selected option */}
                      {selectedOption === "description" && (
                        <div className="p-2" style={{ display: "flex" }}>
                          <h6
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              marginLeft: "8px",
                            }}
                          >
                            {data.details} {" "}
                          </h6>
                        </div>
                      )}
                      {selectedOption === "specification" && (
                        <div className="p-2" style={{ display: "flex" }}>
                          <h6
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              marginLeft: "8px",
                            }}
                          >
                            {data.specifications}
                          </h6>
                        </div>
                      )}
                      {/* {selectedOption === "reviews" && (
                        <div className="p-2" style={{ display: "flex", alignItems: 'center' }}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >







                            <h5
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Name:{" "}
                            </h5>
                            <h5
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Reviews:{" "}
                            </h5>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: 'column' }}
                          >





  

                            <h6
                           
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                marginLeft: "8px",
                              }}
                            >
                              dggdgd
                            </h6>
                            


                            <h6
                           
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                marginLeft: "8px",
                              }}
                            >
                              dggd
                            </h6>
                           
                          </div>
                        </div>
                      )} */}
                      {selectedOption === "reviews" && (
  <div className="p-2" style={{ display: "flex",flexDirection: 'column' }}>
    {data.reviews.map((review, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "column"}}>
      <div style={{ display: "flex" }}></div>
        <h5 style={{ fontWeight: "bold", fontSize: "16px" }}>Name: </h5>
        <h6 style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "8px" }}>{review.name}</h6>
        <h5 style={{ fontWeight: "bold", fontSize: "16px" }}>Reviews: </h5>
        <h6 style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "8px" }}>{review.review}</h6>
      </div>
    ))}
  </div>
)}
                    </div>







        </div>
      </section>

      <Footer />
    </>
  );
};

export default Checkout;
