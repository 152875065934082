import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css"; // Import CSS file containing styles for the component
import axios from "axios";
import Env from "../Environment/Env";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

// import logo from "./logo.png";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, Card, CardContent } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { FaSearch, FaShoppingCart, FaBars } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import SideBar from "../LandingPage/Sidebar";


const Main1 = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);


  const inputRef = useRef(null);
  const popperRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const [isEnabled, setIsEnabled] = useState(true );








  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Check user's login status and role after component mount
    checkUserLogin();
  }, []);

  const checkUserLogin = () => {
    // Simulated logic to check user's login status and role
    const user = localStorage.getItem("user")
    if (user && user.role === "seller" && user.id) {
      setIsLoggedIn(true);
      setUserRole(user.role);
      
    }
  };

  const handleLogout = () => {
    // Handle logout logic here
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    setUserRole("");
    navigate("/login"); // Redirect to login page after logout
  };


  useEffect(() => {
    axios.get(`${Env.server}/api/traderOnOff/GetShowHide`)
      .then((res) =>setIsEnabled(res.data.data[0].TradeShow))
      .catch((err) =>console.log("err===>>>", err));
  }, []);


  const handleTrackOrderClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalStatusShow(false);
  };


  

  const [ModalStatusShow, setModalStatusShow] = useState(false);
  const [SelectedOrderStatus, setSelectedOrderStatus] = useState({});

  const [OrderNumber, setOrderNumber] = useState("");
const handleTrackOrder=()=>{
  console.log("OrderNumber====", OrderNumber)
  axios.get(`${Env.server}/api/order/trackOrderStatus/${OrderNumber}`)
  .then((res) => {
    console.log("res====", res.data.orders)
    setSelectedOrderStatus(res.data.orders)
    setModalStatusShow(true)
  })
  .catch((err) => {
    console.log("err====", err)
  })
  
}


  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    GetAllSubSubCatagory();
    GetAllTypes();

     // Add event listener when component mounts
     document.addEventListener("click", handleClickOutside);

     // Remove event listener when component unmounts
     return () => {
       document.removeEventListener("click", handleClickOutside);
     };

  }, []);



  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };

  const GetAllTypes = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        let resp = res.data.allType;
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", resp)
        const typesArray = resp.map((item) => item.type);

        console.log("typesArray====", typesArray);
        setUniqueTypes(typesArray);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const GetAllSubSubCatagory = () => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        let resp = res.data.subsubcatagories;
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", types)
        // setUniqueTypes(types);
        setAccessoriesData(resp);
        const CatagoryArray = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const SubSubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subsubcatagory
        );

        // Create a Set from the CatagoryArray
        const uniqueOptionsSet = new Set(CatagoryArray);
        const uniqueOptionsArray = Array.from(uniqueOptionsSet);

        // Create a Set from the CatagoryArray
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);

        // Create a Set from the CatagoryArray
        const uniqueOptionsSet2 = new Set(SubSubCatagoryArray);
        const uniqueOptionsArray2 = Array.from(uniqueOptionsSet2); 
        setOptions(uniqueOptionsArray);
        setOptions1(uniqueOptionsArray1);
        setOptions2(uniqueOptionsArray2);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };



  const [NewArrivalOne, setNewArrivalOne] = useState([]);
  const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
  const [NewArrivalThree, setNewArrivalThree] = useState([]);
  const [NewArrivalFour, setNewArrivalFour] = useState([]);
  const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);





  const [sublistContent, setSublistContent] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [NavbarData, setNavbarData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSidebarIcon, setShowSidebarIcon] = useState(window.innerWidth < 550 ? true : false);
  const [showSideBar, setShowSideBar] = useState(window.innerWidth < 550 ? true : false);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < 980){
        setShowSidebarIcon(true);
      }else{
        setShowSidebarIcon(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setShowSidebarIcon(prev => !prev);
  };


  useEffect(() => {
    getAllNavbarData();
    getAllData();
  }, [])


  const getAllNavbarData=()=>{
    axios.get(`${Env.server}/api/filters/gettypePermitedToShow`)
    .then((res) => {
      console.log("allTypesData===", res.data.allType)
      setNavbarData(res.data.allType)
  
    })
    .catch((err) => {
      console.log("err====", err)
    })
  }

  const getAllData = () => {
    axios.post(`${Env.server}/api/retailnewarival/getAll`)
      .then((res) => {
        console.log("Abresc===", res.data.data[0])
        setNewArrivalOne(res.data.data[0].One[0])
        setNewArrivalTwo(res.data.data[0].Two[0])
        setNewArrivalThree(res.data.data[0].Three[0])
        setNewArrivalFour(res.data.data[0].Four[0])
      })
      .catch((err) => {
        console.log("err====", err)
      })
  }


  // const options = ['Option 1', 'Option 2', 'Option 3'];
  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        console.log("Abc=====", res.data.subsubcatagories);
        // setSearchedData(res.data.subsubcatagories);
        console.log("Abc=====", res.data.subsubcatagories);
        

        setGetAllModelsLanding(res.data.subsubcatagories); 
        
        // subcategory-Model
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
        setOptions1(uniqueOptionsArray1);

        // category-brand

        const SubCatagoryArray1 = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
        const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
        setOptions(uniqueOptionsArray11);



      })
      .catch((err) => {
        console.log("err====", err);
      });
  }, []);

  const uniqueSubcategories = new Set(
    GetAllModelsLanding.map((card) => card.subcatagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];

  const uniqueSubcategoriesBrand = new Set(
    GetAllModelsLanding.map((card) => card.catagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

  // Function to handle hover over a category
  const handleMouseEnter = (option) => {
    let TypeData = GetAllModelsLanding.filter( 
      (item) => item.typeName === option
    ); 
  
    let categories = {}; // Object to store categories and their corresponding subcategories
  
    // Populate categories object
    TypeData.forEach((item) => {
      if (!categories[item.catagory]) {
        categories[item.catagory] = [item.subcatagory];
      } else {
        categories[item.catagory].push(item.subcatagory);
      }
    });
  
    // Render sublist content dynamically
    let sublistContent = Object.entries(categories).map(([category, subcategories]) => (
      <Col key={category} md={4} sm={4} lg={4} xl={4} xs={4} style={{ textAlign: "initial", marginTop: '8px' }}>
        <h5 style={{ fontWeight: "bold", fontSize: "13px", marginBottom: '0px', cursor:"pointer" }} className="sublist-headings" onClick={() => {
          setIsHovered(false);
          navigate(`/AmPage4/${option}/${category}`)
          }}>{category}</h5>
        {[...subcategories].slice(0,5).map((subcategory, index) => ( // Convert Set to array and remove duplicates
          <li key={index} style={{ listStyle: "none", fontSize: "11px" , cursor:"pointer" }} className="sublist-sub-headings hover-sublist" onClick={() => {
            setIsHovered(false);
            navigate(`/AccessoryType/${subcategory}/${option}`)
          }}>{subcategory}</li>
        ))}
        {
          [...subcategories].length > 5 && <li style={{ listStyle: "none", fontSize: "11px" , cursor:"pointer" }} className="sublist-sub-headings" onClick={() => {
            setIsHovered(false);
            navigate(`/AmPage4/${option}/${category}`)
          }}>See more</li>
        }
      </Col>
    ));

    // Set the rendered sublist content
    setSublistContent(
      <Container>
        <Row className="sublist-container">
          {sublistContent}
        </Row>
      </Container>
    );
  
    setIsHovered(true); // User is hovering over a list item or sublist
  };
  

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    
    setIsHovered(false); // User is not hovering over a list item or sublist
  };

  

// localStorage.removeItem('selectedProducts')
// localStorage.removeItem('selectedCartItems')
  return (
    <>
    
      <div className="topnavlinks p-1" style={{ color: "#fff", backgroundColor: "#000", fontSize: "12px" }}>
            <div
              className="d-flex justify-content-center align-items-center responsive-text-container"
              style={{ gap: "15px" }}
            >
              <Link to="/Aboutus">
              <p className="responsive-text" style={{  margin: '0px' }}>About</p>
              </Link>
              <Link to="/Contactus">
              <p className="responsive-text" style={{  margin: '0px' }}>Contact Us</p>
              </Link>
              <p onClick={handleTrackOrderClick} className="responsive-text" style={{  cursor: "pointer", margin: '0px' }}>
                Track My Order
              </p>

              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton onClick={()=>setShowModal(false)}>
                  <Modal.Title>Track Your Order</Modal.Title>
                </Modal.Header>
                {ModalStatusShow ? (
                  <>
                  <Modal.Body>
                  {/* Add the contents of your modal here */}
                  {SelectedOrderStatus?.orderNumber ? (
                  <>
                  <h5 style={{color: "green", fontWeight:"bold"}}>Status: {SelectedOrderStatus?.readyStatus}</h5>
                <h5>Order Number: {SelectedOrderStatus?.orderNumber}</h5>
                <h5>Payment Status: {SelectedOrderStatus?.paymentStatus}</h5>
                <h5>Payment Via: {SelectedOrderStatus?.paymentType}</h5>
                <h5>Postal Code: {SelectedOrderStatus?.postalCode}</h5>
                <h5>Order Date: {SelectedOrderStatus?.orderPlaceDate}</h5>
                <h5>Customer Name: {SelectedOrderStatus?.customerName}</h5>
                <h5>Customer Email: {SelectedOrderStatus?.customerEmail}</h5>
                <h5>Address: {SelectedOrderStatus?.customerAddress}</h5>
                <h5>Total Items: {SelectedOrderStatus?.productInfo.length}</h5>
                  </>
                  ):(
                    <>
                    <h4 style={{color: "red", fontWeight:"bold"}}>No Order Found</h4>
                    </>
                  )}

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
                  </>
                ):(
                  <>
                  <Modal.Body>
                  {/* Add the contents of your modal here */}
                  <p>This is where you can track your order...</p>
                <input type="text" placeholder="#123456789" onChange={(e) => setOrderNumber(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" style={{border: '1px solid transparent'}} onClick={handleTrackOrder}>
                    Track
                  </Button>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
                  </>
                )}

              </Modal>
              {/* <p style={{ fontSize: "14px" }}>My Account</p> */}

              {localStorage.getItem("role") === "admin" ? (
                <>
                  <p
                    onClick={() =>
                      (window.location.href =
                        "https://backoffice.mobilebitz.co.uk/")
                    }
                    className="text-center text-black responsive-text"
                    style={{
                      fontSize: "13px",
                      // backgroundColor: "#DB241B",
                      // backgroundColor: '#F8F9FA',
                      // border: '1px solid #DEE2E6',
                      // maxWidth: "90px",
                      // height: "30px", // "Height" should be changed to "height"
                      // border: "none",
                      // padding: "5px",
                      // borderRadius: "5px",
                      margin: '0px'
                    }}
                  >
                   
                      <>My Account</>
                   
                  </p>
                </>
              ) : (
                <>
                {localStorage.getItem("role") === "trader" ? ( 
                  <Link to={"/UserDashboad"}>
                    <p
                      className="text-center text-white responsive-text"
                      style={{
                        fontSize: "12px"
                      }}
                    >
                        <>Trade Dashboard</> 
                    </p>
                  </Link>
                ) : (
                  <>

                  {isEnabled === true ? (
                   <>
                  <Link to={"/TradeSignup"}>
                    
                    <p
                      className="text-center text-black responsive-text "
                      style={{
                        fontSize: "12px"
                      }}
                    >
                       
                        <>Trade</> 
                    </p>
                  </Link>
                   </> 
                  ):null}
                  </>
                )}
                </>
              )}
            </div>
          </div>

    <Navbar expand="lg" className=" pt-2 navbar-padding d-flex jsustify-content-between" style={{borderBottom: '1px solid lightgray', backgroundColor: '#F7DB03'}}>
      
      

      <Container fluid className="pr-0 pl-0 ">
      
        {showSidebarIcon &&  
          <div className="menu-icon" style={{cursor:'pointer'}} onClick={()=>{setShowSideBar(prev=>!prev)}} >
            <FontAwesomeIcon style={{fontSize:'25px',margin:'5px 15px'}} icon={showSideBar ? faTimes : faBars} />
          </div>  
        }
        
        <Navbar.Brand href="/">
              <img
                src={Env.picLogo}
                className="d-inline-block align-top navbar-logo"
                alt="logo"
                style={{
                  minWidth:(showSidebarIcon ? '220px' : '')
                }}
              />
        </Navbar.Brand>

        { (!showSidebarIcon) && <Navbar.Toggle aria-controls="navbarScroll"   />}
          <Navbar.Collapse id="navbarScroll" >
          
            <Form className="m-auto d-flex" style={{  }}>
          

          <div className="">
                <div className="input-group search-bar-lg" style={{margin: '0px'}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="By device, brand or item.."
                    value={searchText}
                    onChange={handleInputChange}
                    onClick={handleClick}
                    style={{ border: '1px solid rgba(85, 85, 85, 1)', fontStyle: 'italic', fontSize: '14px' }}
                    ref={inputRef}
                    
                  />
                  <div className="input-group-append">
                    <span style={{ border: '1px solid rgba(85, 85, 85, 1)', fontSize: '14px'}} className="input-group-text">
                      <i className="bi bi-search"></i>
                    </span>
                  </div>
                </div>
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  style={{ }}
                  ref={popperRef}
                >
                  <Paper
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      width: "auto",
                      
                    }}
                  >
                    <List>
                      {/* Assuming options2, options1, and options are defined */}
                      <ListItemButton disabled>
                        <ListItemText primary="Accessories" />
                      </ListItemButton>
                      {options2
                        .filter((option) =>
                          option?.toLowerCase().includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/AwmPageB/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton onClick={() => setSearchText(option)}>
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                      <ListItemButton disabled>
                        <ListItemText primary="Models" />
                      </ListItemButton>
                      {options1
                        .filter((option) =>
                          option?.toLowerCase().includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/Model/brand/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton onClick={() => setSearchText(option)}>
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                      <ListItemButton disabled>
                        <ListItemText primary="Brand" />
                      </ListItemButton>
                      {options
                        .filter((option) =>
                          option?.toLowerCase().includes(searchText?.toLowerCase())
                        )
                        .map((option, index) => (
                          <Link
                            to={`/BrandPage/${option}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                            key={index}
                          >
                            <ListItemButton onClick={() => setSearchText(option)}>
                              <ListItemText primary={option} />
                            </ListItemButton>
                          </Link>
                        ))}
                    </List>
                  </Paper>
                </Popper>
              </div>

              
          
        </Form>
        <div className="cart-item-icon ml-3"  >
                <Link to={"/Cart1"} className="nav-link" >
                  <i className="bi bi-cart" style={{ fontSize: "25px" }} ></i>
                  <span className="position-absolute top-40 translate-middle badge rounded-pill bg-danger">
                    {(JSON.parse(localStorage.getItem("selectedCartItems")) &&
                      JSON.parse(localStorage.getItem("selectedCartItems"))
                        .length) ||
                      0}
                    <span className="visually-hidden">items in cart</span>
                  </span>
                </Link>
              </div>
              {!localStorage.getItem("role") ? (
    <>
      <Link to="/Singin">
        <div className="login-credential-buttons" style={{ cursor: 'pointer',padding: "6px 12px", borderRadius: '8px',  margin: "0 .8rem 0 0", textDecoration: 'underline'}}>
          {/* <img src="https://ik.imagekit.io/vhfsx9xkeh/icons8-user-64.png?updatedAt=1714408505377" width="40%" height="auto" /> */}
          <h6 style={{margin: 'auto',display: 'flex', alignItems: 'center', justifyContent: 'center' ,fontSize: '14px', textAlign: 'center'}}>Login</h6>
        </div>
      </Link>
      <Link to="/Singup">
        <div className="login-credential-buttons" style={{cursor: 'pointer',padding: "6px 12px", borderRadius: '8px',  margin: "0 .8rem 0 0", textDecoration: 'underline' }}>
          <h6 style={{margin: 'auto',display: 'flex', alignItems: 'center', justifyContent: 'center' ,fontSize: '14px', textAlign: 'center', textWrap: 'nowrap'}}>Sign Up</h6>
        </div>
      </Link>
    </>
  ) : (
    <>
      <Link to="/UserDashboad">
        <div className="login-credential-buttons" style={{cursor: 'pointer', border: '1px solid rgba(85, 85, 85, 0.5)', padding: '5px 13px', borderRadius: '8px', margin: '0 15px 0 0', backgroundColor: 'white' }}>
          <h6 style={{margin: 'auto',display: 'flex', alignItems: 'center', justifyContent: 'center' ,fontSize: '14px', textAlign: 'center', textWrap: 'nowrap'}}>Dashboard</h6>
        </div>
      </Link>
    </>
  )}



          </Navbar.Collapse>
      </Container>
    </Navbar>
      {/* {showSideBar &&  <SideBar showSidebar={showSideBar} closeSideBar={()=>setShowSideBar(false)} />} */}
      <SideBar showSidebar={showSideBar} toggleSidebar={()=>setShowSideBar(false)} />
    </>
  );
};

export default Main1;