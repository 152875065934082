import React, { useEffect, useState, useRef } from "react";
// import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment } from '@fortawesome/free-solid-svg-icons';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons
import Loader from './../loader.gif';
import { FaPlus, FaMinus } from 'react-icons/fa';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { Paper, Grid, Card, CardContent } from '@mui/material'
import { TextField, List, ListItemButton, ListItemText, Popper } from '@mui/material';
const AmPage3 = () => {
    const { type } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getAllByNmeAcc();
    }, [type]);

    const [options, setOptions] = useState(['Choose Options']);

    const [isSelected, setIsSelected] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [ModelGetByParams, setModelGetByParams] = useState([]);
    const [AccessGetByParams, setAccessGetByParams] = useState([]);
    const [allProductData1, setAllProductData1] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [data, setdata] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
    };

    const inputRef = useRef(null);
    const popperRef = useRef(null);

    const [MinRange, setMinRange] = useState(0);
    const [MaxRange, setMaxRange] = useState(6000);

    const [selectedRange, setSelectedRange] = useState(20);

    useEffect(() => {
        getAllByNmeAcc();
        // if(localStorage.getItem("priceFilter")){
        //     setSelectedRange(localStorage.getItem("priceFilter"));
        // }else{ 
        //     localStorage.setItem("priceFilter", 6000);
        // }
        // Add event listener when component mounts
        document.addEventListener("click", handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [])

    const handleClickOutside = (event) => {
        // Close popper if clicked outside of input and popper
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target) &&
            popperRef.current &&
            !popperRef.current.contains(event.target)
        ) {
            setAnchorEl(null);
        }
    };

    const handleRangeChange = (event) => {
        const range = parseInt(event?.target?.value);
        // localStorage.setItem("priceFilter", range);
        setSelectedRange(range);
    };

    // useEffect(()=>{
    //     applyRangeFilter();
    // },[selectedRange,MinRange]);

    const handleFilter = () => {
        // localStorage.setItem("priceFilter", MaxRange);
        // reload page
        // window.location.reload();
    }

    function groupProductsByType(products) {
        const groupedProducts = {};
        products.forEach(product => {
            if (!groupedProducts[product.typeName]) {
                groupedProducts[product.typeName] = [];
            }
            groupedProducts[product.typeName].push(product);
        });
        return groupedProducts;
    }

    const applyFilterProper = async () => {
        setIsLoading(true);
        let url = `${Env.server}/api/subsubcatagory/GetByFilterForRetailByType?min=${MinRange}&max=${selectedRange}&typeName=${encodeURIComponent(type)}`;
        if (selectedFilters.length === 0 && selectedRange === MaxRange) {
            getAllByNmeAcc();
            return;
        }
        console.log('aaaaaaaaaaaaaaaaaaaa', selectedFilters);
        selectedFilters.map(filter => {
            if (filter.type === "Manufacturer") {
                url += `&manufacturerName=${filter.var}`
            }
            if (filter.type === "Color") {
                url += `&color=${filter.var}`
            }
            if (filter.type === "Variant") {
                url += `&variantName=${filter.var}`
            }
        })
        await axios.get(url)
            .then((res) => {
                console.log('aaaaaaaaaaaaaaaaaaa', res.data.data);
                setAllProductData(res.data.data);
            })
            .catch((err) => console.log('aaaaaaaaaaaaaaaaaaaa', err));
        setIsLoading(false);
    }


    const getAllByNmeAcc = async () => {
        setIsLoading(true);
        await axios.get(`${Env.server}/api/subsubcatagory/getAllByType/${type}`)
            .then((res) => {
                console.log("abc====>>>", res.data.subsubcatagories);
                setModelGetByParams(res.data.subsubcatagories);
                setAccessGetByParams(res.data.subsubcatagories)

                setdata(res.data.subsubcatagories)
                console.log("abcccc====>>>", res.data.subsubcatagories);
                const prodArr = res.data.subsubcatagories;
                console.log('fffff', prodArr);
                const maxNum = Math.max(...prodArr.map(product => product.productPrice));
                const minNum = Math.min(...prodArr.map(product => product.productPrice));
                if (minNum === maxNum) {
                    setMinRange(0);
                } else {
                    setMinRange(minNum === Infinity ? 0 : minNum);
                }
                setMaxRange(maxNum === -Infinity ? 0 : maxNum);
                setSelectedRange(maxNum === -Infinity ? 0 : maxNum);



                console.log("res.data.subsubcatagories====", res.data.subsubcatagories)
                setAllProductData(res.data.subsubcatagories)
                setAllProductData1(res.data.subsubcatagories)
                let dataAll = res.data.subsubcatagories

                console.log('Selected Range ==>', selectedRange);
                let filteredItems = dataAll && dataAll.filter(item => parseInt(item.productPrice) <= selectedRange && parseInt(item.productPrice) >= MinRange);
                console.log("muzzamal====", filteredItems)
                setAllProductData(filteredItems)

                let data = res.data.subsubcatagories;
                // console.log("hhhhhhhhhhhhhhhh======", data);
                const combinedFilters = {};

                data.forEach((product) => {
                    if (product.filter) {
                        product.filter.forEach((filterCategory) => {
                            Object.entries(filterCategory).forEach(([key, values]) => {
                                if (values.length === 0) return; // Skip if no data

                                if (!combinedFilters[key]) {
                                    combinedFilters[key] = [];
                                }
                                values.forEach((value) => {
                                    let existingFilter = combinedFilters[key].find(
                                        (existing) => existing.var === value
                                    );
                                    if (!existingFilter) {
                                        combinedFilters[key].push({ var: value, id: [product._id] });
                                    } else {
                                        existingFilter.id.push(product._id);
                                    }
                                });
                            });
                        });
                    }
                });

                console.log("Combined filters:", combinedFilters);
                setFilteredData(combinedFilters);
                const subCatagoryArray = res.data.subsubcatagories.map(data => data.subcatagory);
                setOptions(prevOptions => {
                    const mergedOptions = [...prevOptions, ...subCatagoryArray];
                    const uniqueOptions = Array.from(new Set(mergedOptions));
                    return uniqueOptions;
                });
                handleFiltersSelected();
            })
            .catch((err) => {
                console.log("err====", err)
            })
        setIsLoading(false);
    }

    const uniqueSubcategories = new Set(ModelGetByParams.map(card => card.subcatagory));

    // Convert Set back to an array
    const uniqueSubcategoriesArray = [...uniqueSubcategories];


    const handleCardClick = (selectedProduct, quantity) => {
        if (quantity === undefined) {
            quantity = 1;
        }
        console.log("quantity------", quantity);
        let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];

        // Check if the selected product already exists in the array
        const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

        if (existingProductIndex !== -1) {
            // If the product already exists, update it including quantity
            products[existingProductIndex].quantity = quantity;
        } else {
            // If the product does not exist, add it to the array including quantity
            selectedProduct.quantity = quantity;
            products.push(selectedProduct);
        }

        // Store the updated products array in local storage
        localStorage.setItem('selectedProducts', JSON.stringify(products));
    }

    useEffect(() => {
        applyFilterProper();
    }, [selectedFilters, selectedRange, MinRange]);

    const handleFiltersSelected = () => {
        console.log('here is the data of need ===>>', selectedFilters);
        if (selectedFilters.length > 0) {
            let outputArray = [];
            selectedFilters.forEach((filter) => {
                const filterid = filter.id[0];
                outputArray = [...outputArray, ...allProductData.filter(prod => filterid.includes(prod._id))];
            });
            setAllProductData(outputArray);
        }
    }

    const handleCheckboxClick = (filterVar, productId, filterName) => {
        setSelectedFilters(prevFilters => {
            const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);

            if (selectedIndex === -1) {
                const newFilters = [...prevFilters, { var: filterVar, id: [productId], type: filterName }];
                console.log('Here is the data vvv=>>', newFilters);
                return newFilters;
            } else {
                const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
                return newFilters;
            }
        });
    };




    const handleInputChange = (event) => {
        setSearchText(event?.target?.value?.toLowerCase());
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };



    const [productQuantities, setProductQuantities] = useState({});


    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    // Function to handle decreasing the quantity for a specific product
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] && productQuantities[productId] > 0) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };

    // Function to handle changing the quantity directly for a specific product
    const handleQuantityChange = (productId, event) => {
        const value = parseInt(event?.target?.value);
        if (!isNaN(value) && value >= 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: value
            }));
        }
    };




    const [showFilter, setShowFilter] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const cardsPerPage = 6;

    const handleNext = () => {
        const newIndex = Math.min(startIndex + cardsPerPage, uniqueSubcategoriesArray.length - cardsPerPage);
        setStartIndex(newIndex);
    };

    const handlePrev = () => {
        const newIndex = Math.max(startIndex - cardsPerPage, 0);
        setStartIndex(newIndex);
    };

    const visibleSubcategories = uniqueSubcategoriesArray.slice(startIndex, startIndex + cardsPerPage);





    return (
        <>

            <Navbar />


            <div className="container-fluid">
                <h3 className="text-center mt-3" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>Search Model</h3>

                <div className="container ">
                    <div className="row">
                        <div className="col">
                            <div className="row align-items-center" style={{ backgroundColor: '#404040' }}>

                                <div className="col-lg-9">

                                    <div className="input-group mb-3 " style={{}}>
                                        <p style={{ fontSize: '1.5vw', color: 'white' }}>Enter the name of your Device</p>
                                        <div style={{ position: 'relative', display: 'inline-block', width: '900px', backgroundColor: 'white' }}>
                                            <TextField
                                                id="search"
                                                label="Search..."
                                                variant="outlined"
                                                value={searchText}
                                                ref={inputRef}
                                                onChange={handleInputChange}
                                                onClick={handleClick}
                                                sx={{ width: '100%', zIndex: "0" }} // Set the width of the TextField
                                                InputLabelProps={{
                                                    style: {
                                                        fontStyle: 'italic', // Set label to italic
                                                        fontSize: '14px', // Set label font size


                                                    }
                                                }}

                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SearchIcon style={{ margin: '0' }} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ width: '400px' }} ref={popperRef}>
                                                <Paper style={{
                                                    maxHeight: "250px",
                                                    overflowY: "auto",
                                                    width: "auto",
                                                }}>
                                                    <List>
                                                        {options
                                                            .filter(option => option?.toLowerCase().includes(searchText))
                                                            .map((option, index) => (
                                                                <Link to={`/AmPage/${option}/${type}`}>
                                                                    <ListItemButton key={index} onClick={() => setSearchText(option)}>
                                                                        <ListItemText primary={option} />
                                                                    </ListItemButton>
                                                                </Link>
                                                            ))}
                                                    </List>
                                                </Paper>
                                            </Popper>
                                        </div>
                                        {/* <button className="btn btn-outline-primary" type="button">
                                        <i className="bi bi-search"></i>
                                    </button> */}
                                    </div>

                                </div>

                                <div className="col-lg-3 pt-3">
                                    <img srcSet="https://ik.imagekit.io/vhfsx9xkeh/51b0dc3495d6c4b4fbd95d83189abf93.png?updatedAt=1712280463325" style={{ maxWidth: '100%', height: 'auto' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-6 col-sm-6 col-md-4 col-lg-1">
                            <button style={{ backgroundColor: 'none', border: 'none' }} className="btn" onClick={handlePrev} disabled={startIndex === 0}>
                                <FaChevronLeft size={40} style={{ margin: '10px' }} /> {/* Icon for previous */}
                            </button>
                        </div>

                        {visibleSubcategories.map((subcategory, index) => (
                            <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-1" style={{ padding: 'initial' }}>
                                <Link to={`/AmPage/${subcategory}/${type}`}>
                                    <div className="card mb-4 bg-white align-items-center pt-4" style={{ border: 'none' }}>
                                        <div className="view overlay">
                                            <img className="card-img-top" style={{ height: "100px", width: "100px" }} src={getAsset(ModelGetByParams.find(card => card.subcatagory === subcategory).imageSubsubCatagory[0])} alt="Card image cap" />
                                            <div className="mask rgba-white-slight"></div>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title text-center" style={{ fontSize: '13px' }}>{subcategory}</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}


                        <div className="col-6 col-sm-6 col-md-4 col-lg-1">
                            <button style={{ backgroundColor: 'none', border: 'none', float: 'inline-end' }} className="btn" onClick={handleNext} disabled={startIndex + cardsPerPage >= uniqueSubcategoriesArray.length}>
                                <FaChevronRight size={40} style={{ margin: '10px' }} /> {/* Icon for next */}
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="d-md-none ml-2 m-md-5 mb-2">
                        <div className="d-md-none ml-2 m-md-5 mb-2">
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                    backgroundColor: '#f44336', // A more visually appealing red color
                                    color: 'white',
                                    cursor: 'pointer',
                                    borderRadius: '12px',
                                    width: 'fit-content',
                                    alignItems: 'center',
                                    padding: '10px 15px', // Added padding for better spacing
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow for depth
                                    transition: 'all 0.3s ease' // Smooth transition for hover effect
                                }}
                                onClick={() => setShowFilter(prev => !prev)}
                            >
                                <p style={{
                                    fontSize: '18px',
                                    margin: 0
                                }}>{showFilter ? 'Hide ' : 'Show '}Filter</p>
                                <p style={{
                                    fontSize: '22px',
                                    border: '2px solid white',
                                    borderRadius: '50%', // Rounded border for icon
                                    padding: '5px 10px', // Adjust padding for better icon size
                                    margin: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '32px',
                                    height: '32px',
                                    backgroundColor: '#d32f2f' // Slightly darker red for contrast
                                }}>{showFilter ? '-' : '+'}</p>
                            </div>
                        </div>

                    </div>
                    <div className={showFilter ? " w-full  " : "d-none d-lg-block col-lg-3 col-md-2"}>
                        {/* {range} */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <input
                                    type="range"
                                    min={MinRange}
                                    max={MaxRange + 1}
                                    step="1"
                                    value={selectedRange}
                                    onChange={handleRangeChange}
                                />
                            </div>
                            <div style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                                <input type="text" style={{ width: '35%' }} value={MinRange} onChange={(e) => {
                                    setMinRange(e.target.value)
                                    // localStorage.setItem("minRange",e.target.value);
                                }} placeholder="" /> - to -
                                <input type="text" style={{ width: '35%' }} value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} placeholder="" />
                                <button onClick={handleFilter} style={{ width: '50%', borderRadius: "17px", border: "1px solid transparent", backgroundColor: '#DB241B', color: 'white', padding: '5px', marginTop: '8px' }} >Filter</button>
                            </div>
                        </div>

                        <div>
                            {/* Map over the filtered data and create checkboxes */}
                            {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                                // Check if filterName is not "Variant"
                                filterName !== "Variant" && filterName !== "ProductPrice" &&
                                <div className="box w-75 my-4">
                                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                                    <div className="p-2">
                                        {/* Map over filter values and create checkboxes */}
                                        {filterValues.map(filter => (
                                            <div key={filter.var} className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={filter.var}
                                                    checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                                                    onClick={() => handleCheckboxClick(filter.var, filter.id, filterName)}
                                                />
                                                <label className="form-check-label" style={{ textWrap: 'nowrap' }} htmlFor={filter.var}>
                                                    {filter.var}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>



                    <div className="col-lg-9">
                        <div className="container">
                            {
                                isLoading ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }} >
                                        <img src={Loader} alt="Loading..." width={120} height={120} />
                                    </div> :
                                    <>

                                        {allProductData && (
                                            Object.entries(groupProductsByType(allProductData)).map(([type, products]) => (
                                                <div key={type}>
                                                    <h3 className="" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>{type}</h3>
                                                    <div className="row">
                                                        {
                                                            isLoading ?
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Loading ....</div> :
                                                                <>
                                                                    {products.map((card, index) => (
                                                                        <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                                            <Link to={`/Checkout/${card._id}`}>
                                                                                <div className="card mb-4 align-items-center pt-4 pr-2 pl-2" style={{ cursor: "pointer", backgroundColor: "white" }}>
                                                                                    <div className="view overlay">
                                                                                        <img className="card-img-top" style={{ height: "110px", width: "110px", border: "none" }} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                                                                        <a href="#!">
                                                                                            <div className="mask rgba-white-slight"></div>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="row align-items-center pt-2">
                                                                                        {card.subsubcatagory && card.subsubcatagory.split(" ").length > 8 ? (
                                                                                            <h5 style={{ fontSize: '13px', lineHeight: "18px" }} className="card-text text-left">
                                                                                                {card.subsubcatagory && card.subsubcatagory.split(" ").slice(0, 8).join(" ")}...
                                                                                            </h5>
                                                                                        ) : (
                                                                                            <h5 style={{ fontSize: '13px', lineHeight: "18px" }} className="card-text text-left">
                                                                                                {card.subsubcatagory}
                                                                                            </h5>
                                                                                        )}
                                                                                        {card.subcatagory && <div>{card.subcatagory}</div>}
                                                                                        <div style={{ display: 'flex', alignItems: 'center', margin: "4px 0px" }}>
                                                                                            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
                                                                                            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
                                                                                            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
                                                                                            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
                                                                                            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
                                                                                        </div>
                                                                                        <h5 style={{ fontSize: '13px', marginTop: '3px' }} className="card-title text-left">£{card.productPrice}</h5>

                                                                                        <div style={{ display: 'flex' }}>
                                                                                            <img src="https://ik.imagekit.io/vhfsx9xkeh/Ok.png?updatedAt=1712168587135" width="8%" height="8%" />
                                                                                            <h5 style={{ fontSize: '13px', lineHeight: "18px", marginLeft: '5px' }} className="card-text text-left">
                                                                                                {card.qty === 0 ? <span style={{ fontSize: '13px', color: "red" }} className="card-text text-left">Out of Stock</span> : <span style={{ fontSize: '13px', color: "green" }} className="card-text text-left">In Stock</span>}

                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>

                                                                                    </div>

                                                                                    {/* <div className="col-md-12 mt-3">
                                                            <div className="row align-items-center">
                                                              
                                                                <div className="col-md-12 ">
                                                                    <button className="btn" style={{ backgroundColor: '#DB241B', color: "white", cursor: "pointer", width: "100%" }} onClick={() => {
                                                                       
                                                                        navigate(`/Checkout/${card._id}`);
                                                                    }}>Buy</button>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div> */}
                                                                                    <br />
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </>
                            }
                        </div>
                    </div>

                </div>
            </div >

        </>
    );

}

export default AmPage3;